import { createExpressionParser } from '../modules/expressionParser';
export const createJsonBuilder = () => {
    const expressionParser = createExpressionParser();
    const buildJSON = async (structuredJson, properties) => {
        await Promise.all(properties.map(async (property) => {
            if (property.type === 'Number') {
                const { value: numberProp } = await expressionParser.parse(property.value, 'skip');
                if (numberProp !== undefined && numberProp !== '<empty>') {
                    const parsedNumber = parseFloat(expressionParser.stripHtml(numberProp));
                    structuredJson[property.name] = parsedNumber;
                }
            }
            else if (property.type === 'String') {
                const { value: stringProp } = await expressionParser.parse(property.value, 'skip');
                if (stringProp !== undefined && stringProp !== '<empty>') {
                    const parsedString = expressionParser.stripHtml(stringProp);
                    structuredJson[property.name] = parsedString;
                }
            }
            else if (property.type === 'Boolean') {
                const { value: booleanProp } = await expressionParser.parse(property.value, 'skip');
                if (booleanProp !== undefined && booleanProp !== '<empty>') {
                    const parsedBoolean = typeof booleanProp === 'string' ? expressionParser.stripHtml(booleanProp) === 'true' : !!booleanProp;
                    structuredJson[property.name] = parsedBoolean;
                }
            }
            else if (property.type === 'Object') {
                if (!structuredJson[property.name]) {
                    structuredJson[property.name] = {};
                }
                structuredJson[property.name] = await buildJSON(structuredJson[property.name], property.children);
            }
            else if (property.type === 'Array') {
                if (!Array.isArray(structuredJson[property.name])) {
                    structuredJson[property.name] = [];
                }
                if (property.dynamic) {
                    let { value } = await expressionParser.parse(property.value, 'strip');
                    try {
                        JSON.parse(value);
                    }
                    catch (e) {
                        value = `[${value.substring(1, value.length - 1)}]`;
                    }
                    structuredJson[property.name] = JSON.parse(value);
                }
                else {
                    structuredJson[property.name] = await buildJSONArray(structuredJson[property.name], property.value);
                }
            }
        }));
        return structuredJson;
    };
    const buildJSONArray = async (propArray, value) => {
        const parsedArray = await Promise.all(value.map(async (v) => {
            if (v.type === 'Number') {
                const { value: numberProp } = await expressionParser.parse(v.value, 'strip');
                const parsedNumber = parseFloat(numberProp);
                return parsedNumber;
            }
            else if (v.type === 'String') {
                const { value: stringProp } = await expressionParser.parse(v.value, 'unwrap');
                return stringProp;
            }
            else if (v.type === 'Boolean') {
                const { value: booleanProp } = await expressionParser.parse(v.value, 'strip');
                const parsedBoolean = typeof booleanProp === 'string' ? booleanProp === 'true' : !!booleanProp;
                return parsedBoolean;
            }
            else if (v.type === 'Object') {
                return await buildJSON({}, v.children);
            }
        }));
        propArray = [
            ...propArray,
            ...parsedArray
        ];
        return propArray;
    };
    return {
        buildJSON,
        buildJSONArray
    };
};
