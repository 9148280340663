import Vue from 'vue';
import VueRouter from 'vue-router';
const packageJson = require('../../package.json');
const appVersion = packageJson.version;

Vue.use(VueRouter);

const routes = [
    {
        path: '/health-check', // Health-check route for Traefik
        name: 'HealthCheck',
        component: {
            render(h) {
                return h('div', JSON.stringify({
                    service: 'app-client',
                    status: 'up',
                    appVersion,
                    time: new Date().toISOString()
                }));
            }
        }
    },
    {
        path: '/:moduleId',
        name: 'Main',
        component: () => import(/* webpackChunkName: "main" */ '../components/Main.vue')
    },
    {
        path: '/:moduleId/:customUrl',
        name: 'MainCustomURL',
        component: () => import(/* webpackChunkName: "main" */ '../components/Main.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: window.env.BASE_URL,
    routes
});

export default router;
