import { getExpressionParser } from '../../modules/expressionParser';
import { getVariableParser } from '../../modules/variableParser';
import { getSessionClient } from '../../modules/sessionClient';
import { parseArray, stringifyArray } from '../../modules/formula-parsers/formula-utils/arrayFormulas';
export const createSearchArrayParser = () => {
    const expressionParser = getExpressionParser();
    const variableParser = getVariableParser();
    const sessionClient = getSessionClient();
    const parse = async (data) => {
        const { arrayReference, searchCriteria, currentItemReference, indexReference } = data.data;
        // parse array
        const { value: rawArrayValue } = await expressionParser.parse(arrayReference, 'strip');
        const parsedArray = parseArray(rawArrayValue);
        // parse variable IDs
        const currentItemReferenceId = variableParser.extractReferenceId(currentItemReference);
        const indexReferenceId = variableParser.extractReferenceId(indexReference);
        const matchingValues = [];
        for (const index in parsedArray) {
            let currentItem = parsedArray[index];
            if (currentItem) {
                if (Array.isArray(currentItem)) {
                    currentItem = stringifyArray(currentItem);
                }
                else if (typeof currentItem === 'object') {
                    currentItem = JSON.stringify(currentItem);
                }
                else {
                    currentItem = `${currentItem}`;
                }
            }
            // update loop values
            await Promise.all([
                indexReferenceId ? sessionClient.write({ referenceId: indexReferenceId, value: index }) : null,
                currentItemReferenceId ? sessionClient.write({ referenceId: currentItemReferenceId, value: currentItem }) : null
            ]);
            // search element
            const isMatchFound = (await expressionParser.parse(searchCriteria, 'strip')).value;
            if (isMatchFound) {
                matchingValues.push(parsedArray[index]);
            }
        }
        const processedValue = stringifyArray(matchingValues);
        return {
            value: processedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
