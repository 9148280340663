import { getExpressionParser } from '../../modules/expressionParser';
export const createSplitTextParser = () => {
    const expressionParser = getExpressionParser();
    const extractWithRegex = (inputString, pattern) => {
        var _a;
        const regex = new RegExp(pattern);
        const match = inputString === null || inputString === void 0 ? void 0 : inputString.match(regex);
        if (match) {
            const matchingPart = match[0];
            const nonMatchingParts = inputString === null || inputString === void 0 ? void 0 : inputString.split(matchingPart);
            return [matchingPart === null || matchingPart === void 0 ? void 0 : matchingPart.trim(), (_a = nonMatchingParts === null || nonMatchingParts === void 0 ? void 0 : nonMatchingParts.join('')) === null || _a === void 0 ? void 0 : _a.trim()];
        }
        return ['', inputString];
    };
    const parse = async (data) => {
        const { isDelimiterRegex } = data.data;
        const { value: inputString } = await expressionParser.parse(data.data.stringReference, 'strip');
        const { value: regex } = await expressionParser.parse(data.data.regex, 'strip');
        const { value: delimeter } = await expressionParser.parse(data.data.delimeter, 'strip');
        let resultArr = [];
        if (isDelimiterRegex) {
            resultArr = extractWithRegex(inputString, regex);
        }
        else {
            resultArr = inputString === null || inputString === void 0 ? void 0 : inputString.split(delimeter);
        }
        const processedValue = JSON.stringify(resultArr);
        return {
            value: `{${processedValue.substring(1, processedValue.length - 1)}}`,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
