import { parsePlatformArray } from '../helpers/utils';
export const createConditionsMapper = (variable, value) => {
    const conditionsMapper = {
        equals: () => `"${variable}" == "${value}"`,
        'does not equal': () => `"${variable}" != "${value}"`,
        'less than': () => `${parseFloat(variable)} < ${parseFloat(value)}`,
        'more than': () => `${parseFloat(variable)} > ${parseFloat(value)}`,
        'less than or equal to': () => `${parseFloat(variable)} <= ${parseFloat(value)}`,
        'more than or equal to': () => `${parseFloat(variable)} >= ${parseFloat(value)}`,
        empty: () => `"${variable}" == ""`,
        'not empty': () => `"${variable}" != ""`,
        contains: () => variable.includes(value) ? '1 == 1' : '1 != 1',
        'does not contain': () => !variable.includes(value) ? '1 == 1' : '1 != 1',
        'begins with': () => variable.substring(0, value.length) === value ? '1 == 1' : '1 != 1',
        'ends with': () => variable.substring(variable.length - value.length) === value ? '1 == 1' : '1 != 1',
        'is part of': () => {
            const arrVal = parsePlatformArray(value);
            return Array.isArray(arrVal) && arrVal.includes(variable) ? '1 == 1' : '1 != 1';
        },
        'is not part of': () => {
            const arrVal = parsePlatformArray(value);
            return Array.isArray(arrVal) && !arrVal.includes(variable) ? '1 == 1' : '1 != 1';
        },
    };
    return {
        conditionsMapper
    };
};
