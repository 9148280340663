/**
 * Checks if a URL is valid http/https URL
 * @param url
 */
export const isValidHttpUrl = (url) => {
    try {
        const newUrl = new URL(url);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    }
    catch (err) {
        return false;
    }
};
export const isJSON = (text) => {
    try {
        JSON.parse(text);
        return true;
    }
    catch (err) {
        return false;
    }
};
export const isPrimitve = (value) => ['string', 'boolean', 'number', 'symbol', 'bigint'].includes(typeof value) || value === null || value === undefined;
export const isObject = (value) => !isPrimitve(value) && !Array.isArray(value) && typeof value === 'object';
export const flattenObjectWithDotNotation = (source, target = {}, prefix = '') => {
    target = target || {};
    prefix = prefix || '';
    if (source && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (typeof (source[key]) === 'object') {
                if (Array.isArray(source[key])) {
                    target[prefix + key] = JSON.stringify(source[key]);
                }
                flattenObjectWithDotNotation(source[key], target, prefix + key + '.');
            }
            else {
                return target[prefix + key] = source[key];
            }
        });
    }
    return target;
};
export const getDistinctArray = (arr) => {
    const set = new Set(arr);
    return [...set];
};
export const parsePlatformArray = (rawArrayValue) => {
    var _a, _b;
    if (!rawArrayValue) {
        return [];
    }
    let values = [];
    if ((rawArrayValue === null || rawArrayValue === void 0 ? void 0 : rawArrayValue.startsWith('[')) && (rawArrayValue === null || rawArrayValue === void 0 ? void 0 : rawArrayValue.endsWith(']'))
        ||
            (rawArrayValue === null || rawArrayValue === void 0 ? void 0 : rawArrayValue.startsWith('{')) && (rawArrayValue === null || rawArrayValue === void 0 ? void 0 : rawArrayValue.endsWith('}'))) {
        values = ((_b = (_a = rawArrayValue.substring(1, rawArrayValue.length - 1)) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map(item => {
            return item.replace(/["\\]/g, ''); // Removes " and \ from each item
        })) || [];
    }
    else {
        values = [rawArrayValue];
    }
    return values;
};
