import { getExpressionParser } from '../../modules/expressionParser';
export const createFileLinkGeneratorParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.fileName, 'unwrap');
        parsedExpression.value = `${process.env.HOSTED_INTERPRETER_SERVICE_URL}/${parsedExpression.value}`;
        return parsedExpression;
    };
    return {
        parse
    };
};
