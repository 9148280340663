import { getExpressionParser } from '../../modules/expressionParser';
export const createRightParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.str.value, 'unwrap');
        const plainText = expressionParser.stripHtml(parsedExpression.value);
        const right = plainText.slice(0 - parseInt(data.data.str.numOfCharacters));
        parsedExpression.value = parsedExpression.value.replace(plainText, right);
        return parsedExpression;
    };
    return {
        parse
    };
};
