import { getExpressionParser } from '../../modules/expressionParser';
export const createCombineExpressionsParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { expressions } = data.data;
        let result = false;
        for (const currentExpression of expressions) {
            const lazyParseExpression = () => expressionParser.parse(currentExpression.value, 'strip');
            if (currentExpression.operator === 'AND') {
                result = result && (await lazyParseExpression()).value;
            }
            else if (currentExpression.operator === 'OR') {
                result = result || (await lazyParseExpression()).value;
            }
            else {
                result = (await lazyParseExpression()).value;
            }
            result = !!result;
        }
        result = result ? 'true' : '';
        return {
            value: result,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
