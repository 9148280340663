// eslint-disable-next-line @typescript-eslint/no-var-requires
const filtrex = require('filtrex'); // filtrex doesn't support ESModules
import { getExpressionParser } from '../../modules/expressionParser';
import { createConditionsMapper } from '../../common/condition';
export const createIfConditionParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { value: leftText } = await expressionParser.parse(data.data.leftStatement, 'strip');
        const { value: rightText } = await expressionParser.parse(data.data.rightStatement, 'strip');
        const trueResultText = await expressionParser.parse(data.data.trueResult, 'unwrap');
        const falseResultText = await expressionParser.parse(data.data.falseResult, 'unwrap');
        const expression = getExpression(leftText, data.data.operator, rightText);
        const evaluator = filtrex.compileExpression(expression);
        if (evaluator({})) {
            return trueResultText;
        }
        return falseResultText;
    };
    const getExpression = (variable, operation, value) => {
        const { conditionsMapper } = createConditionsMapper(variable, value);
        const conditionGenerator = conditionsMapper[operation];
        return conditionGenerator();
    };
    return {
        parse
    };
};
