import { getExpressionParser } from '../../modules/expressionParser';
export const createLowerParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.value, 'unwrap');
        parsedExpression.value = parsedExpression.value.toLowerCase();
        return parsedExpression;
    };
    return {
        parse
    };
};
