import { isPrimitve } from '../../helpers/utils';
import { getExpressionParser } from '../../modules/expressionParser';
import { parseArray, stringifyArray } from '../../modules/formula-parsers/formula-utils/arrayFormulas';
export const createSortArrayParser = () => {
    const expressionParser = getExpressionParser();
    const compareItems = (firstItem, secondItem, isAscending) => {
        let result = 0;
        if (!(firstItem && secondItem)) {
            return result;
        }
        if (firstItem > secondItem) {
            result = isAscending ? 1 : -1;
        }
        else if (secondItem > firstItem) {
            result = isAscending ? -1 : 1;
        }
        return result;
    };
    const sort = (inputArray, isAscending, accessPath) => {
        if (!Array.isArray(inputArray)) {
            return inputArray;
        }
        const newArray = [...inputArray];
        newArray.sort((a, b) => {
            const aValue = accessPath ? getValueByAccessPath(a, accessPath) : a;
            const bValue = accessPath ? getValueByAccessPath(b, accessPath) : b;
            return compareItems(aValue, bValue, isAscending);
        });
        return newArray;
    };
    const getValueByAccessPath = (object, path) => {
        const accessedValue = path.split('.').reduce((result, key) => {
            if (!isPrimitve(result)) {
                if (Array.isArray(result)) {
                    result = result[parseInt(key)];
                }
                else {
                    result = result[key];
                }
            }
            return result;
        }, object);
        return accessedValue;
    };
    const parse = async (data) => {
        const { isAsc, sortByAccessPath, arrayReference, accessPath } = data.data;
        const { value: rawArrayValue } = await expressionParser.parse(arrayReference, 'strip');
        const parsedArray = parseArray(rawArrayValue);
        const parsedAccessPath = await expressionParser.parse(accessPath, 'unwrap');
        const sortedArray = sort(parsedArray, isAsc, sortByAccessPath ? parsedAccessPath.value : '');
        const processedValue = stringifyArray(sortedArray);
        return {
            value: processedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
