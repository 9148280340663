import axios from 'axios';

/**
 ** Creates http service configuration for making http requests
 * @param {string} BASE_REQUEST_URL
 * @param {string} RESOURCE_PATH
 * @param {import('axios').AxiosRequestConfig} [httpOptions]
 */
export const createHttpServiceConfig = (BASE_REQUEST_URL, RESOURCE_PATH, httpOptions = {}) => ({
    BASE_REQUEST_URL,
    RESOURCE_PATH,
    // @ts-ignore
    get ENDPOINT() {
        return this.BASE_REQUEST_URL + this.RESOURCE_PATH;
    },
    http: axios.create({
        baseURL: BASE_REQUEST_URL + RESOURCE_PATH,
        ...httpOptions
    }),
    /**
     * Adds a request interceptor (code that executes just before making an http request) to the service
     * @callback RequestInterceptor
     * @param {import('axios').AxiosRequestConfig} axiosConfig
     * @returns {import('axios').AxiosRequestConfig}
     * @param {RequestInterceptor} interceptor
     */
    addRequestInterceptor (interceptor) {
        return this.http.interceptors.request.use(config => interceptor(config));
    }
});

// @NOTE: All request interceptors should accept axiosConfig as parameter and return the same

/**
 * This should be used to configure auth header via 'addRequestInterceptor' for auth protected services.
 * @param {import('axios').AxiosRequestConfig} axiosConfig 
 */
export const authInterceptor = (axiosConfig) => {
    const authJSON = localStorage.getItem('vue-session-key');
    const authInfo = authJSON ? JSON.parse(authJSON) : null;
    if (authInfo) {
        axiosConfig.headers.common.Authorization = `Bearer ${authInfo.jwt}`;
    }
    return axiosConfig;
};
