import { getDistinctArray } from '../helpers/utils';
import { createConditionChecker } from '../modules/conditionChecker';
import { createEventEmitter } from '../helpers/eventEmitter';
let workflowEngine = null;
export const createWorkflowEngine = () => {
    let currentNode = null;
    let pauseExecutionAt = [];
    let shouldLogEvents = true;
    const conditionChecker = createConditionChecker();
    const eventEmitter = createEventEmitter();
    const init = ({ pauseExecutionAtNodes = [], logWorkflowEvents = true }) => {
        currentNode = null;
        pauseExecutionAt = pauseExecutionAtNodes;
        shouldLogEvents = logWorkflowEvents;
    };
    const run = async ({ nodes, executeFrom = null, inProgress = false, executeExplicitNode = false }) => {
        var _a;
        if (!(nodes === null || nodes === void 0 ? void 0 : nodes.length)) {
            return;
        }
        currentNode = executeFrom || nodes[0];
        if (currentNode) {
            await execute(currentNode);
        }
        if (!executeExplicitNode) {
            if (pauseExecutionAt.includes(currentNode.type) && !inProgress) {
                return;
            }
            const executionOrder = ((_a = currentNode === null || currentNode === void 0 ? void 0 : currentNode.connections) === null || _a === void 0 ? void 0 : _a.length) ? await getExecutionOrder(currentNode.connections) : null;
            if (executionOrder) {
                const pendingNodes = executionOrder.pendingNodes.map(async (nodeId) => {
                    const executeFromNode = nodes.find(node => node.nodeId === nodeId);
                    if (executeFromNode) {
                        await run({ nodes, inProgress, executeFrom: executeFromNode });
                    }
                });
                const skippedNodes = executionOrder.skippedNodes.map(async (nodeId) => {
                    const skippedNode = nodes.find(node => node.nodeId === nodeId);
                    if (skippedNode) {
                        await notifySkippedNode(skippedNode);
                    }
                });
                await Promise.all([
                    ...pendingNodes,
                    ...skippedNodes
                ]);
            }
        }
    };
    const getExecutionOrder = async (connections) => {
        const connectionsWithConditions = connections.filter(connection => { var _a, _b, _c; return ((_c = (_b = (_a = connection === null || connection === void 0 ? void 0 : connection.condition) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.length) && !hasAlwaysCondition(connection); });
        const connectionsWithElseConditions = connections.filter(connection => { var _a; return ((_a = connection === null || connection === void 0 ? void 0 : connection.condition) === null || _a === void 0 ? void 0 : _a.label) === 'else'; });
        const connectionsWithNoConditions = connections.filter(connection => { var _a; return ((_a = connection === null || connection === void 0 ? void 0 : connection.condition) === null || _a === void 0 ? void 0 : _a.label) === 'always' || hasAlwaysCondition(connection); });
        const pendingConnections = [];
        const connectionsToBeChecked = connectionsWithConditions.map(async (connection) => {
            const isConditionSatisfied = !!(await conditionChecker.isSatisfied(connection.condition.query));
            return {
                ...connection,
                isConditionSatisfied
            };
        });
        const checkedConnections = await Promise.all(connectionsToBeChecked);
        // add connections (edges) with satisfied conditions
        pendingConnections.push(...checkedConnections.filter(connection => connection.isConditionSatisfied));
        if (!pendingConnections.length) {
            // add else condition connnections to execution order in case no condition is satisfied
            pendingConnections.push(...connectionsWithElseConditions);
        }
        pendingConnections.push(...connectionsWithNoConditions);
        const pendingConnectionIds = pendingConnections.map(connection => connection.id);
        const skippedConnections = connections.filter(connection => !pendingConnectionIds.includes(connection.id));
        const executionOrder = {
            pendingNodes: getDistinctArray(pendingConnections.map(connection => connection.targetNodeId)),
            skippedNodes: getDistinctArray(skippedConnections.map(connection => connection.targetNodeId))
        };
        return executionOrder;
    };
    const execute = async (node) => {
        logEvent('Executing node: ', {
            name: node.data.name,
            nodeId: node.nodeId
        });
        await eventEmitter.emit('execute', node);
    };
    const notifySkippedNode = async (node) => {
        logEvent('Skipping node: ', {
            name: node.data.name,
            nodeId: node.nodeId
        });
        await eventEmitter.emit('skip', node);
    };
    const logEvent = (message, payload) => {
        if (shouldLogEvents) {
            console.log(message, payload);
        }
    };
    const hasAlwaysCondition = (connection) => { var _a, _b, _c; return !!((_c = (_b = (_a = connection.condition) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.some(qChild => qChild.query.operand === 'Always go through')); };
    workflowEngine = {
        run,
        init,
        on: eventEmitter.on,
        off: eventEmitter.off
    };
    return getWorkflowEngine();
};
export const getWorkflowEngine = () => {
    if (!workflowEngine) {
        throw '@/lib/nuclicore-core/esm: workflow engine is not configured';
    }
    return workflowEngine;
};
