// lib
// eslint-disable-next-line @typescript-eslint/no-var-requires
const filtrex = require('filtrex'); // filtrex doesn't support ESModules
// internal deps
import { getExpressionParser } from '../../modules/expressionParser';
import { flattenObjectWithDotNotation } from '../../helpers/utils';
import { getSessionClient } from '../../modules/sessionClient';
import { parseArray } from '../../modules/formula-parsers/formula-utils/arrayFormulas';
export const createArrayFilterParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { value: parsedExpression } = await expressionParser.parse(data.data.array, 'strip');
        const array = parseArray(parsedExpression);
        const element = array.find(element => !!isCriteriaSatisfied(data.data, element));
        const variables = flattenObjectWithDotNotation(element || {});
        const session_values = Object.entries(variables).map(([variableReference, value]) => ({
            referenceId: variableReference,
            value
        }));
        const sessionClient = getSessionClient();
        await sessionClient.write(...session_values);
        return {
            value: JSON.stringify(element),
            isPlainText: true
        };
    };
    const isCriteriaSatisfied = (data, element) => {
        const operator = data.query.condition === 'All' ? 'and ' : ' or ';
        const expression = parseQueryToExpression(data.query.properties, element, operator);
        const evaluator = filtrex.compileExpression(expression);
        return evaluator({});
    };
    const parseQueryToExpression = (properties, element, logicalOperator) => {
        let expression = '';
        for (let i = 0; i < properties.length; i++) {
            const variable = element[properties[i].name.replace(/(<([^>]+)>)/gi, '').trim()];
            const value = properties[i].value.replace(/(<([^>]+)>)/gi, '').trim();
            const operation = properties[i].operator;
            expression += parseQueryOperation(variable, operation, value, logicalOperator);
        }
        return expression.slice(0, -4);
    };
    const parseQueryOperation = (variable, operation, value, logicalOperator) => {
        switch (operation) {
            case 'equals':
                return '"' + variable + '"' + ' == ' + '"' + value + '" ' + logicalOperator;
            case 'does not equal':
                return '"' + variable + '"' + ' != ' + '"' + value + '" ' + logicalOperator;
        }
    };
    return {
        parse
    };
};
