import { getExpressionParser } from '../../modules/expressionParser';
export const createUpperParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.value, 'unwrap');
        parsedExpression.value = parsedExpression.value.toUpperCase();
        return parsedExpression;
    };
    return {
        parse
    };
};
