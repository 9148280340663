import { getExpressionParser } from '../../modules/expressionParser';
import { tz as momentTz } from 'moment-timezone';
export const createManipulateDateParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { value, year, month, date, hour, minute, second, timezone } = data.data;
        const parsedExpression = await expressionParser.parse(value, 'strip');
        const parsedDate = momentTz(parsedExpression.value, timezone);
        parsedDate.set({
            year: parseInt(year) || parsedDate.year(),
            month: parseInt(month) || parsedDate.month(),
            date: parseInt(date) || parsedDate.date(),
            hour: parseInt(hour) || parsedDate.hour(),
            minute: parseInt(minute) || parsedDate.minute(),
            second: parseInt(second) || parsedDate.second()
        });
        return {
            value: parsedDate.format(),
            isPlainText: true
        };
    };
    return {
        parse
    };
};
