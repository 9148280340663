import { getExpressionParser } from '../../modules/expressionParser';
export const createMultipleConditionParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { value: switchValue } = await expressionParser.parse(data.data.value, 'strip');
        for (const option of data.data.options) {
            const optionCase = await expressionParser.parse(option.case, 'unwrap');
            const plainTextCaseValue = expressionParser.stripHtml(optionCase.value);
            const optionValue = await expressionParser.parse(option.value, 'unwrap');
            if (switchValue == plainTextCaseValue) {
                return optionValue;
            }
        }
        if (data.data.hasDefault) {
            const defaultValue = await expressionParser.parse(data.data.defaultValue, 'unwrap');
            return defaultValue;
        }
        return {
            value: '',
            isPlainText: true
        };
    };
    return {
        parse
    };
};
