/**
 * Creates an event based pub/sub system
 */
export const createEventEmitter = () => {
    const listeners = {};
    /**
     * Subscribes handler with an event
     */
    const on = (eventName, callback) => {
        if (!listeners[eventName]) {
            listeners[eventName] = [];
        }
        listeners[eventName].push(callback);
    };
    /**
     * Unsubscribes handler for an event
     */
    const off = (eventName, callback) => {
        if (listeners[eventName]) {
            listeners[eventName] = listeners[eventName].filter(cb => cb !== callback);
        }
    };
    /**
     * Triggers all handlers for a subscribed event with provided data
     */
    const emit = async (eventName, ...params) => {
        const eventHandlers = listeners[eventName] || [];
        await Promise.all(eventHandlers.map(async (callback) => await callback(...params)));
    };
    return {
        emit,
        off,
        on
    };
};
