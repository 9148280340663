// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { BUILD_SERVICE_ENDPOINT, DEPLOYMENT_RESOURCE_PATH } from './buildServiceConstants';

const buildServiceConfig = createHttpServiceConfig(BUILD_SERVICE_ENDPOINT, DEPLOYMENT_RESOURCE_PATH);

//-- request interceptors --//
buildServiceConfig.addRequestInterceptor(authInterceptor);

/**
 * @param id
 */
export const fetchDeploymentsService = (id) => buildServiceConfig.http({
    method: 'GET',
    url: `?id=${id}`,
});

/**
 * @param {import('./buildServiceTypes').IFetchDeploymentByModuleAndEnvParams} params
 */
export const fetchDeploymentsServiceByModuleAndEnvironment = (params) => buildServiceConfig.http({
    method: 'GET',
    params
});
