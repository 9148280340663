import { getExpressionParser } from '../../modules/expressionParser';
import { createJsonBuilder } from '../../modules/jsonBuilder';
import { getVariableParser } from '../../modules/variableParser';
import { getSessionClient } from '../../modules/sessionClient';
import { parseArray, stringifyArray } from '../../modules/formula-parsers/formula-utils/arrayFormulas';
export const createMapArrayParser = () => {
    const expressionParser = getExpressionParser();
    const jsonBuilder = createJsonBuilder();
    const parse = async (data) => {
        const { arrayReference, indexReference, mappingItem } = data.data;
        const variableParser = getVariableParser();
        const sessionClient = getSessionClient();
        // parse array
        const { value: rawArrayValue } = await expressionParser.parse(arrayReference, 'strip');
        const parsedArray = parseArray(rawArrayValue);
        // parse loop index ID
        const indexReferenceId = variableParser.extractReferenceId(indexReference);
        const mappedArray = [];
        for (const index in parsedArray) {
            if (indexReferenceId) {
                // update loop index
                await sessionClient.write({
                    referenceId: indexReferenceId,
                    value: index
                });
            }
            // generate mapped item
            let mappedItem = null;
            if (mappingItem.type === 'Object') {
                mappedItem = await jsonBuilder.buildJSON({}, mappingItem.value);
            }
            else {
                mappedItem = (await expressionParser.parse(mappingItem.value, 'strip')).value;
            }
            mappedArray.push(mappedItem);
        }
        const processedValue = stringifyArray(mappedArray);
        return {
            value: processedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
