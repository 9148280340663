export const parseArray = (rawArrayValue) => {
    let parsedValue = [];
    if (rawArrayValue.charAt(0) !== '[' && rawArrayValue.charAt(rawArrayValue.length - 1) !== ']') {
        if (rawArrayValue.charAt(0) === '{' && rawArrayValue.charAt(rawArrayValue.length - 1) === '}') {
            rawArrayValue = `[${rawArrayValue.substring(1, rawArrayValue.length - 1)}]`;
        }
        else {
            // value is primitive, convert to array
            rawArrayValue = `["${rawArrayValue}"]`;
        }
    }
    parsedValue = JSON.parse(rawArrayValue);
    return parsedValue;
};
export const stringifyArray = (array) => {
    let strigifiedArray = '';
    strigifiedArray = JSON.stringify(array);
    strigifiedArray = `{${strigifiedArray.substring(1, strigifiedArray.length - 1)}}`;
    return strigifiedArray;
};
