// @ts-check
import { ref, set, computed } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { VARIABLE_STORE } from '@/store/common/storeConstants';

export const useVariableStore = defineStore(VARIABLE_STORE, () => {
    /** @type {import('@/store/types/variableStore').Variables} */
    const variables = ref({});
    /** @type {import('@/store/types/variableStore').Variables} */
    const intialVariables = ref({});

    const variablesValues = computed(() => {
        return Object.entries(variables.value).reduce((result, [key, variable]) => {
            result[key] = variable.value;
            return result;
        }, {});
    });

    /**
     * @param {string} id
     */
    const isVariableIgnored = (id) => !!variables.value[id]?.isValueIgnored;

    /**
     * Performs a patch update on specified variable 
     * @param {import('@/store/types/variableStore').IVariableIdParam} id 
     * @param {Partial<import('@/store/types/variableStore').IVariable>} payload 
     */
    const updateVariableById = (id, payload) => {
        let variableId = id.value;
        if (id.type === 'field') {
            // if it's a field's ID, try fetching variable ID from field ID
            variableId = Object.keys(variables.value).find(key => key.includes(variableId));
        }
        if (variableId) {
            // generate updated variable by patching/merging existing values with new values
            const updatedVariable = {
                ...(variables.value[variableId] || {
                    value: '',
                    isValueIgnored: false
                }),
                ...(payload)
            };
            if (variableId in variables.value) {
                variables.value[variableId] = updatedVariable;
            } else {
                set(variables.value, variableId, updatedVariable);
            }
        }
    };

    return {
        variables,
        intialVariables,
        variablesValues,
        isVariableIgnored,
        updateVariableById
    };
});
