import * as cheerio from 'cheerio';
import { createIfConditionParser } from '../modules/formula-parsers/ifCondition';
import { createMultipleConditionParser } from '../modules/formula-parsers/multipleCondition';
import { createArithmeticCalculationParser } from '../modules/formula-parsers/arithmeticCalculation';
import { createConcatenateParser } from '../modules/formula-parsers/concatenate';
import { createLeftParser } from '../modules/formula-parsers/left';
import { createRightParser } from '../modules/formula-parsers/right';
import { createUpperParser } from '../modules/formula-parsers/upper';
import { createLowerParser } from '../modules/formula-parsers/lower';
import { createNowParser } from '../modules/formula-parsers/now';
import { createTransformTimezoneParser } from '../modules/formula-parsers/transformTimezone';
import { createDateArithmeticParser } from '../modules/formula-parsers/dateArithmeticCalculation';
import { createArrayFilterParser } from '../modules/formula-parsers/arrayFilter';
import { createBase64EncoderParser } from '../modules/formula-parsers/base64Encoder';
import { createNumberFormatterParser } from '../modules/formula-parsers/numberFormatter';
import { createManipulateDateParser } from '../modules/formula-parsers/manipulateDate';
import { createAccessPropertyParser } from '../modules/formula-parsers/accessProperty';
import { createMapArrayParser } from '../modules/formula-parsers/mapArray';
import { createDateFormatterParser } from '../modules/formula-parsers/dateFormatter';
import { createInsertFormattedTextParser } from '../modules/formula-parsers/insertFormattedText';
import { createCombineExpressionsParser } from '../modules/formula-parsers/combineExpressions';
import { createSortArrayParser } from '../modules/formula-parsers/sortArray';
import { createFileLinkGeneratorParser } from '../modules/formula-parsers/generateFileLink';
import { createSplitTextParser } from '../modules/formula-parsers/splitText';
import { createReduceArrayParser } from '../modules/formula-parsers/reduceArray';
import { createSearchArrayParser } from '../modules/formula-parsers/searchArray';
export let formulaParser = null;
export const createFormulaParser = () => {
    // init formula parsers here
    const ifConditionParser = createIfConditionParser();
    const multipleConditionParser = createMultipleConditionParser();
    const arithmeticCalculationParser = createArithmeticCalculationParser();
    const concatenateParser = createConcatenateParser();
    const leftParser = createLeftParser();
    const rightParser = createRightParser();
    const upperParser = createUpperParser();
    const lowerParser = createLowerParser();
    const nowParser = createNowParser();
    const transformTimezoneParser = createTransformTimezoneParser();
    const dateArithmeticParser = createDateArithmeticParser();
    const arrayFilterParser = createArrayFilterParser();
    const base64EncoderParser = createBase64EncoderParser();
    const numberFormatterParser = createNumberFormatterParser();
    const manipulateDateParser = createManipulateDateParser();
    const accessPropertyParser = createAccessPropertyParser();
    const mapArrayParser = createMapArrayParser();
    const dateFormatterParser = createDateFormatterParser();
    const insertFormattedTextParser = createInsertFormattedTextParser();
    const combineExpressionsParser = createCombineExpressionsParser();
    const sortArrayParser = createSortArrayParser();
    const fileLinkGeneratorParser = createFileLinkGeneratorParser();
    const splitTextParser = createSplitTextParser();
    const reduceArrayParser = createReduceArrayParser();
    const searchArrayParser = createSearchArrayParser();
    const parse = async (value) => {
        let parsedValue = {
            value,
            isPlainText: true
        };
        if (value) {
            const htmlDoc = cheerio.load(value);
            const body = htmlDoc.root().find('body');
            const formulas = body.find('.formula');
            for (const formula of formulas) {
                const excel_formula = cheerio.default(formula).data('formula');
                switch (excel_formula.formula) {
                    case 'ACCESS PROPERTY':
                        parsedValue = await accessPropertyParser.parse(excel_formula);
                        break;
                    case 'IF CONDITION IS TRUE':
                        parsedValue = await ifConditionParser.parse(excel_formula);
                        break;
                    case 'MULTIPLE CONDITION RESULT':
                        parsedValue = await multipleConditionParser.parse(excel_formula);
                        break;
                    case 'ARITHMETIC CALCULATION':
                        parsedValue = await arithmeticCalculationParser.parse(excel_formula);
                        break;
                    case 'CONCATENATE':
                        parsedValue = await concatenateParser.parse(excel_formula);
                        break;
                    case 'LEFT':
                        parsedValue = await leftParser.parse(excel_formula);
                        break;
                    case 'RIGHT':
                        parsedValue = await rightParser.parse(excel_formula);
                        break;
                    case 'TO LOWER':
                        parsedValue = await lowerParser.parse(excel_formula);
                        break;
                    case 'TO UPPER':
                        parsedValue = await upperParser.parse(excel_formula);
                        break;
                    case 'DATE ARITHMETICS':
                        parsedValue = await dateArithmeticParser.parse(excel_formula);
                        break;
                    case 'NOW':
                        parsedValue = await nowParser.parse(excel_formula);
                        break;
                    case 'CONVERT DATE TIMEZONE':
                        parsedValue = await transformTimezoneParser.parse(excel_formula);
                        break;
                    case 'FILTER ARRAY':
                        parsedValue = await arrayFilterParser.parse(excel_formula);
                        break;
                    case 'ENCODE TO BASE64':
                        parsedValue = await base64EncoderParser.parse(excel_formula);
                        break;
                    case 'FORMAT NUMBER':
                        parsedValue = await numberFormatterParser.parse(excel_formula);
                        break;
                    case 'MANIPULATE DATE':
                        parsedValue = await manipulateDateParser.parse(excel_formula);
                        break;
                    case 'MAP ARRAY':
                        parsedValue = await mapArrayParser.parse(excel_formula);
                        break;
                    case 'FORMAT DATE':
                        parsedValue = await dateFormatterParser.parse(excel_formula);
                        break;
                    case 'INSERT FORMATTED TEXT':
                        parsedValue = await insertFormattedTextParser.parse(excel_formula);
                        break;
                    case 'COMBINE EXPRESSIONS':
                        parsedValue = await combineExpressionsParser.parse(excel_formula);
                        break;
                    case 'SORT ARRAY':
                        parsedValue = await sortArrayParser.parse(excel_formula);
                        break;
                    case 'GENERATE FILE LINK':
                        parsedValue = await fileLinkGeneratorParser.parse(excel_formula);
                        break;
                    case 'SPLIT TEXT':
                        parsedValue = await splitTextParser.parse(excel_formula);
                        break;
                    case 'REDUCE ARRAY':
                        parsedValue = await reduceArrayParser.parse(excel_formula);
                        break;
                    case 'SEARCH ARRAY':
                        parsedValue = await searchArrayParser.parse(excel_formula);
                        break;
                }
                let formulaToReplace = cheerio.default(formula).wrap('<span>').parent().html();
                const wrappedFormula = `<p>${formulaToReplace}</p>`;
                formulaToReplace = excel_formula.formula === 'INSERT FORMATTED TEXT' && value.includes(wrappedFormula) ? wrappedFormula : formulaToReplace;
                if (formulaToReplace) {
                    value = value.replace(formulaToReplace, `${parsedValue.value}`);
                }
                parsedValue.value = value;
            }
        }
        return parsedValue;
    };
    formulaParser = { parse };
    return getFormulaParser();
};
export const getFormulaParser = () => {
    if (formulaParser === null) {
        throw '@/lib/nuclicore-core/esm: formula parser is not configured';
    }
    return formulaParser;
};
