// @ts-check
import { defineStore } from 'pinia';
import { ref, set } from '@vue/composition-api';
import { WORKFLOW_STORE } from '@/store/common/storeConstants';
import { fetchDeploymentsServiceByModuleAndEnvironment } from '@/services/build-service/deploymentRequests';
import { useApplicationStore } from '@/store/applicationStore';

export const useWorkflowStore = defineStore(WORKFLOW_STORE, () => {
    const applicationStore = useApplicationStore();

    /** @type {import('@/store/types/workflowStore').Workflows} */
    const workflows = ref({});
    /** @type {import('@/store/types/workflowStore').WorkflowsVariables} */
    const workflowsVariables = ref({});

    /**
     * @param {string} screenNodeId 
     * @param {string} fieldId
     */
    const getWorkflowByFieldId = (screenNodeId, fieldId) => {
        let workflow = null;
        if (workflows.value[screenNodeId] && workflows.value[screenNodeId][fieldId]) {
            workflow = workflows.value[screenNodeId][fieldId];
        }
        return workflow;
    };
    /**
     * @param {string} screenNodeId 
     * @param {string} fieldId
     * @param {string} workflowId 
     */
    const fetchWorkflow = async (screenNodeId, fieldId, workflowId) => {
        try {
            if (!(screenNodeId in workflows.value) && applicationStore.deploymentInfo) {
                const { data: { data } } = await fetchDeploymentsServiceByModuleAndEnvironment({
                    module_id: workflowId,
                    environment_id: applicationStore.deploymentInfo.environment_id,
                    append_nodes_info: true
                });
                if (!workflows.value[screenNodeId]) {
                    set(workflows.value, screenNodeId, {});
                }
                if (!workflows.value[screenNodeId][fieldId]) {
                    set(workflows.value[screenNodeId], fieldId, {});
                }
                workflows.value[screenNodeId][fieldId] = data;
            }
        } catch (err) {
            console.error(err);
        }
    };
    /**
     * @param {string} variableReference
     * @param {Partial<import('@/store/types/variableStore').IVariable>} payload
     */
    const updateWorkflowVariable = (variableReference, payload) => {
        if (variableReference) {
            // generate updated variable by patching/merging existing values with new values
            const updatedVariable = {
                ...(workflowsVariables.value[variableReference] || {
                    value: '',
                    isValueIgnored: false
                }),
                ...(payload)
            };
            if (variableReference in workflowsVariables.value) {
                workflowsVariables.value[variableReference] = updatedVariable;
            } else {
                set(workflowsVariables.value, variableReference, updatedVariable);
            }
        }
    };

    return {
        workflows,
        workflowsVariables,
        getWorkflowByFieldId,
        fetchWorkflow,
        updateWorkflowVariable
    };
});
