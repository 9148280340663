// lib
// eslint-disable-next-line @typescript-eslint/no-var-requires
const filtrex = require('filtrex'); // filtrex doesn't support ESModules
import { decode } from 'html-entities';
// internal deps
import { getSessionClient } from '../modules/sessionClient';
import { createConditionsMapper } from '../common/condition';
import { createExpressionParser } from '../modules/expressionParser';
export const createConditionChecker = (conditionCheckerConfig = { decodeHtml: false }) => {
    const sessionClient = getSessionClient();
    const expressionParser = createExpressionParser();
    const isSatisfied = async (condition) => {
        var _a;
        if (!((_a = condition === null || condition === void 0 ? void 0 : condition.children) === null || _a === void 0 ? void 0 : _a.length)) {
            return true;
        }
        const { query } = condition.children.slice(-1)[0];
        if (query.rule === 'always') {
            return true;
        }
        if (query.rule === 'random') {
            return Math.random() < 0.5;
        }
        const rules = condition.children;
        const logicalOperator = condition.logicalOperator === 'all' ? 'and' : 'or';
        const expression = await parseQueryToExpression(rules, logicalOperator);
        console.log(expression);
        const evaluator = filtrex.compileExpression(expression);
        return evaluator({});
    };
    const parseValue = async (rule) => {
        let { value } = await expressionParser.parse(rule.query.value || '', 'strip');
        if (value === 'null') {
            value = '';
        }
        return value;
    };
    const parseQueryOperation = (variable, operation, value, logicalOperator) => {
        value = conditionCheckerConfig.decodeHtml ? decode(value) : value;
        const { conditionsMapper } = createConditionsMapper(variable, value);
        const operationParser = conditionsMapper[operation];
        let parsedOperation = operationParser();
        if (logicalOperator) {
            parsedOperation += ` ${logicalOperator} `;
        }
        return parsedOperation;
    };
    const parseQueryToExpression = async (rules, logicalOperator) => {
        let expression = '';
        const queryBuilderRules = rules.filter(rule => rule.type === 'query-builder-rule');
        const parsedRules = await Promise.all(queryBuilderRules.map(async (rule) => {
            const [variable] = await sessionClient.read(rule.query.rule);
            const value = await parseValue(rule);
            return {
                ...rule,
                variable,
                value
            };
        }));
        parsedRules.forEach((rule, index) => {
            expression += parseQueryOperation(rule.variable || '', rule.query.operator, rule.value, index < queryBuilderRules.length - 1 ? logicalOperator : undefined);
        });
        return expression;
    };
    return {
        isSatisfied
    };
};
