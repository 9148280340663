import { getExpressionParser } from '../../modules/expressionParser';
export const createConcatenateParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = {
            value: '',
            isPlainText: true
        };
        const stringList = [];
        const parsedDelimiter = await parseDelimiter(data.data.glue, data.data.customSeparator);
        for (const str of data.data.instances) {
            const parsedString = await expressionParser.parse(str.value, 'unwrap');
            stringList.push(parsedString.value);
            if (!parsedString.isPlainText) {
                parsedExpression.isPlainText = parsedString.isPlainText;
            }
        }
        parsedExpression.value = stringList.join(parsedDelimiter);
        return parsedExpression;
    };
    const parseDelimiter = async (delimiter, custom) => {
        const delimiterMapper = {
            Space: () => ' ',
            Comma: () => ',',
            Custom: async () => custom ? (await expressionParser.parse(custom, 'unwrap')).value : ''
        };
        const delimiterParser = delimiterMapper[delimiter];
        const parsedDelimiter = await delimiterParser();
        return parsedDelimiter;
    };
    return {
        parse
    };
};
